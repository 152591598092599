.div_body_img_lobby {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  background-size: cover;
}
.div_head_box {
  display: flex;
  align-items: end;
  border: white 2px solid;
  background: #ffffffe6;
  padding: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem #00000013 !important;
  border-radius: 0.25rem;
  & svg {
    font-size: 30px;
  }
  & h4 {
    font-size: 20px;
    margin-bottom: 0px;
    margin-left: 8px;
  }
}
.div_manage_pass {
  display: flex;
  align-items: center;
  & svg {
    font-size: 30px;
  }
  & span {
    font-size: 18px;
    margin-bottom: 0px;
    margin-left: 8px;
  }
}
.div_setting_user {
  padding-right: 15px;
}
.div_setting_telephone {
  padding-left: 15px;
}
.div_btn_chang_pass {
  display: flex;
  align-items: center;
}
.btn_submit3 {
  background: linear-gradient(90deg, #3285ff, #3285ff);
  width: 100%;
}
.div_img_bank {
  display: flex;
  justify-content: center;
}
.img_bank {
  width: 50%;
}
.container_loader {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.txt_loader h1 {
  color: #f2d638;
  font-size: 16px;
  font-weight: 400;
  margin: 2px auto;
}
.loading {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading .line_box {
  padding: 2px;
  width: 226.3083451202px;
  // height: 20px;
  border: 2px solid #ffc107;
  border-radius: 20px;
}
.loading .line_box .line {
  width: 226.3083451202px;
  height: 16px;
  color: #f2d638;
  border: 2px solid;
  position: relative;
  filter: drop-shadow(0 0 10px #f2d638);
  border-radius: 20px;
  background: #f2d638;
  animation: loading 2s forwards cubic-bezier(0, 0, 0, 0);
}
// LOADING CLOCK
.clock {
  border-radius: 70px;
  border: 3px solid #fff;
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* margin-left: -50px; */
  /* margin-top: -50px; */
  display: block;
  width: 50px;
  height: 50px;
}
.clock:after {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 6px;
  left: 48%;
  height: 16px;
  width: 4px;
  border-radius: 5px;
  transform-origin: 50% 97%;
  animation: grdAiguille 2s linear infinite;
}
.clock:before {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 9px;
  left: 47%;
  height: 13px;
  width: 4px;
  border-radius: 5px;
  transform-origin: 50% 94%;
  animation: ptAiguille 12s linear infinite;
}
@media only screen and (max-width: 768px) {
  .div_setting_telephone {
    padding-left: 0px;
  }
  .div_setting_user {
    padding-right: 0px;
  }
}