// @import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  font-family: saysettha_ot, sans-serif !important;
}
@font-face {
  font-family: 'saysettha_ot';
  src: url('../fonts/saysettha_ot.ttf') format('woff2'),
    url('../fonts/saysettha_ot.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@import "custom";
@import "custom_swiper_slice";
@import "custom_header";
@import "custom_footer";
@import "custom_lotto_isnot_login";
@import "custom_modal";
@import "custom_login";
@import "custom_register";
@import "custom_lotto_is_login";
@import "custom_game";
@import "custom-lotto";
@import "custom-animetion";
@import "custom-deposit";
@import "custom_withdraw";
@import "custom_aff";
@import "custom_pro";

html,
body {
  // background: #0A1933;
  height: 100%;
  margin: 0;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #dad4d4;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888888;
    border-radius: 8px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}