.div_container_lotto_is_login {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    margin-bottom: 10px;
    margin-top: 1rem;
}
.div_broad_cast {
    background: transparent linear-gradient(180deg, #a7f7dd 0%, #2d9d77 70%, #167A58 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    height: 35px;
    color: #fff;
    display: flex;
    justify-content: center;
}
.marquee_sec {
    width: 95%;
    z-index: 9;
    overflow: hidden;
    padding: 2px;
    text-align: center;
    align-items: center;
    display: flex;
}
.p_lotto_new {
    margin: 0 auto;
    font-size: 25px;
    color: #fff;
}
.div_lotto_new {
    background: transparent linear-gradient(180deg, #559f88c4 0%, #b9dacfba 50%, #559f88c4 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    margin-top: 0.8rem;
    height: 100%;
}
.div_lotto_new_sec1 {
    margin-top: 0.5rem;
}
.div_time_lotto_new {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent linear-gradient(180deg, rgba(243, 244, 245, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%) 0% 0% no-repeat padding-box;
    border-radius: 27px;
    width: 100%;
    padding: 5px;
    height: fit-content;
    color: white;
    font-size: 1.2rem;
}
.img_carendar_lotto_new {
    width: 1.5rem;
}
.spn_carendar_lotto_new {
    margin-left: 5px;
}
.div_img_flag_near_lotto {
    position: relative;
    text-align: center;
    align-self: center;
    // display: flex;
    width: 30%;
    margin-left: -0.70rem;
}
.flag_near_close_lotto {
    width: 50%;
    border-radius: 50%;
    margin-right: 3px;
    text-align: center;
    height: 45px;
}
.icon_blend {
    width: 50%;
    border-radius: 50%;
    box-shadow: 2px 2px 3px #00000029;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    opacity: 0.7;
    z-index: 1;
}
.spn_countdown_last {
    color: #1A1A1A;
    font-size: 14px;
    white-space: nowrap;
}
.div_countdown_lotto_closed {
    display: flex;
    align-items: center;
}
.div_countdown_ {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
    border-radius: 9px;
    text-align: center;
    width: 6rem;
    padding: 1px 15px 1px 5px;
    font-size: 14px;
}
.txt_red {
    color: #FF0000;
}
.txt_green {
    color: #15C296;
}
.div_txt_lotto_closed {
    background: #FF0000 0% 0% no-repeat padding-box;
    color: white;
    border-radius: 9px;
    padding: 2px 10px 3px 10px;
    font-size: 12px;
    margin-left: -5%;
}
.div_txt_lotto_closed_orange {
    color: #000;
    text-align: center;
    align-self: center;
    background: #FBB03B 0% 0% no-repeat padding-box;
    border-radius: 9px;
    padding: 2px 10px 3px 10px;
    font-size: 12px;
    margin-left: -5%;
}
.div_lotto_detail_last_huay {
    background: #FF0000 0% 0% no-repeat padding-box;
    border-radius: 30px;
    border: 3px solid #D9E5EE;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.5rem !important;
}
.div_lotto_detail_last_huay_main_green {
    background: transparent linear-gradient(90deg, #000000 1%, #00C300 66%) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    border: 3px solid #D9E5EE;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.5rem !important;
}
.div_lotto_img_countdown {
    display: flex;
}
.div_lotto_detail_last_sec1 {
    background: transparent linear-gradient(90deg, #D9E5EE 34%, #CBD4DB 60%, #c65149 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 26px 0px 0px 26px;
    font-size: 1.3rem;
}
.div_lotto_detail_last_sec1_ {
    background: transparent linear-gradient(180deg, #D9E5EE 0%, #CBD4DB 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 26px 0px 0px 26px;
    font-size: 1.3rem;
}
.div_lotto_detail_last_sec2 {
    text-align: center;
    color: #fff;
    transition: 0.1s ease;
    cursor: pointer;
}
.div_lotto_detail_last_sec2:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition: 0.1s ease;
}
// 22222
.div_menu_user_body {
    background: transparent linear-gradient(180deg, #559f88c4 0%, #b9dacfba 50%, #88bbaaba 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    margin-top: 0.8rem;
    height: 100%;
}
.div_munu_user_body_sec1 {
    border-radius: 10px 10px 0px 0px;
    padding: 15px;
    font-size: 1.2rem;
    color: #fff;
    // margin-top: 0.5rem;
}
.div_munu_user_body_sec1_1 {
    display: flex;
    justify-content: space-between;
}
.div_munu_user_body_sec1_1 {
    & p {
        margin-bottom: 0px !important;
        font-size: 16px;
    }
}
.div_setting_user_body_sec1_1 {
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}
.div_setting_user_body_sec1_1:hover {
    color: #000;
}
.div_munu_user_body_sec2 {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 25px;
    overflow: hidden;
    display: flex;
}
.div_munu_user_body_sec2_1 {
    width: 60%;
    text-align: center;
}
.div_txt_balance {
    font-size: 25px;
    font-weight: 500;
    margin-top: 0.5rem;
}
.p_txt_balance {
    margin-bottom: 0px !important;
    font-size: 40px;
    font-weight: 600;
    padding-top: 0.25rem;
    font-style: italic;
}
.div_munu_user_body_sec2_2 {
    width: 40%;
}
.div_img_user_deposit {
    & img {
        width: 100%;
        transition: 0.1s ease;
        cursor: pointer;
    }
    & img:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        transition: 0.1s ease;
    }
}
.div_munu_user_body_sec3 {
    display: flex;
    margin-top: 0.5rem;
}
.div_munu_user_body_sec3_1 {
    width: 33.3%;
    & img {
        padding: 3px;
        width: 100%;
        transition: 0.1s ease;
        cursor: pointer;
    }
    & img:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        transition: 0.1s ease;
    }
}
.div_munu_user_body_sec3_2 {
    width: 33.3%;
    & img {
        padding: 3px;
        width: 100%;
        transition: 0.1s ease;
        cursor: pointer;
    }
    & img:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        transition: 0.1s ease;
    }
}
.div_munu_user_body_sec3_3 {
    width: 33.3%;
    & img {
        padding: 3px;
        width: 100%;
        transition: 0.1s ease;
        cursor: pointer;
    }
    & img:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        transition: 0.1s ease;
    }
}
.div_munu_user_body_sec4 {
    width: 100%;
    & img {
        padding: 5px;
        width: 100%;
        transition: 0.1s ease;
        cursor: pointer;
    }
    & img:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        transition: 0.1s ease;
    }
}
.div_munu_user_body_sec5 {
    width: 100%;
    & img {
        padding: 5px;
        width: 100%;
        transition: 0.1s ease;
        cursor: pointer;
    }
    & img:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        transition: 0.1s ease;
    }
}
.div_munu_user_body_sec6 {
    width: 100%;
    display: flex;
}
.div_munu_user_body_sec6_1 {
    width: 40%;
}
.div_munu_user_body_sec6_1_1 {
    position: relative;
}
.img_div_munu_user_body_sec6_1_1 {
    padding: 5px;
    width: 100%;
    transition: 0.1s ease;
    cursor: pointer;
}
.img_div_munu_user_body_sec6_1_1:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    transition: 0.1s ease;
}
.div_munu_user_body_sec6_1_1_2 {
    position: absolute;
    top: 55%;
    font-size: 22px;
    left: 50%;
    translate: -50% -50%;
    color: black;
    font-weight: bolder;
}
.img_div_munu_user_body_sec6_1_2 {
    width: 100%;
    transition: 0.1s ease;
    cursor: pointer;
}
.img_div_munu_user_body_sec6_1_2:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    transition: 0.1s ease;
}
.div_munu_user_body_sec6_2 {
    width: 60%;
}
.img_menu_btn_data {
    padding: 5px;
    width: 100%;
    transition: 0.1s ease;
    cursor: pointer;
}
.img_menu_btn_data:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    transition: 0.1s ease;
}
@media only screen and (max-width: 1200px) {}
@media only screen and (max-width: 900px) {
    .grid_lotto_new {
        margin-bottom: 10rem;
    }
    .div_lotto_new_sec1 {
        margin-top: 0rem;
    }
    .div_lotto_detail_last_huay_main_green {
        margin-bottom: 0.5rem !important;
    }
    .div_lotto_detail_last_huay {
        margin-bottom: 0.5rem !important;
    }
    .div_lotto_new {
        padding: 1rem;
        margin-bottom: 10rem;
        height: auto;
    }
    .div_munu_user_body_sec1 {
        margin-bottom: 0.5rem;
    }
    // .div_lotto_new {
    //     background: transparent linear-gradient(180deg, rgba(167, 74, 92, 0.8) 0%, rgba(245, 118, 139, 0.8) 50%, rgba(167, 74, 92, 0.8) 100%) 0% 0% no-repeat padding-box;
    // }
    .p_txt_balance {
        font-size: 20px;
    }
    .div_txt_balance {
        font-size: 18px;
    }
    // 22222
    .div_menu_user_body {
        padding: 0.5rem;
        margin-top: 0rem;
    }
}
@media only screen and (max-width: 768px) {
    .flag_near_close_lotto {
        height: 40px;
    }
    .div_container_lotto_is_login {
        margin-top: 4.5rem;
    }
}