.div_footer_main {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 40px;
    color: #fff;
    // height: 100%;
    font-size: 18px !important;
    vertical-align: middle;
    display: flex !important;
    justify-content: center;
    align-items: center;
    & p {
        margin: 0 auto;
        font-weight: 400;
    }
}
.footer_login {
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    transform: none;
    display: flex;
    justify-content: space-around;
    z-index: 19;
    padding-bottom: 5px;
}
.ul_footer_login {
    display: flex;
    margin: 0 auto;
    padding: 0px;
    width: 100%;
    justify-content: space-around;
    & li {
        list-style-type: none;
        align-items: center;
        display: flex;
    }
}
.img_footer_login {
    width: 100%;
    max-height: 44px;
    margin-top: 0.5rem !important;
    transition: 0.1s ease;
    cursor: pointer;
}
.img_footer_login:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition: 0.1s ease;
}
@media only screen and (max-width: 768px) {
    .div_footer_main {
        & p {
            font-size: 18px;
        }
    }
}